import { GAuthor, GUser } from "../graphqlQueryPartials";
import { GArticleListView, GArticleModalView } from "./article";
import { GDocumentListView, GDocumentModalView } from "./document";
import { GInternalDocumentListView, GInternalDocumentModalView } from "./internalDocument";

export const GInternalCollectionModalView = `
  id
  title
  nodeType: __typename
  contentTypeName
  internalCollectionFields {
    internalDocuments: internalCollectionInternalDocumentConnection {
      ... on InternalDocument {
        ${GInternalDocumentModalView}
      }
    }
    publicPosts: internalCollectionDocumentConnection {
      ... on Document {
        ${GDocumentModalView}
      }
      ... on Article {
        ${GArticleModalView}
      }
    }
  }
`;

export const GInternalCollectionListView = `
  id
  databaseId
  title
  nodeType: __typename
  contentTypeName
  postCount
  author {
    node {
      ${GAuthor}
    }
  }
  collaborators {
    ${GUser}
  }
  images
  internalCollectionFields {
    private
    staffOnly
    siteCollection
    internalDocuments: internalCollectionInternalDocumentConnection {
      ... on InternalDocument {
        ${GInternalDocumentListView}
      }
    }
    publicPosts: internalCollectionDocumentConnection {
      ... on Document {
        ${GDocumentListView}
      }
      ... on Article {
        ${GArticleListView}
      }
    }
  }
`;

export const GInternalCollection = `
  id
  databaseId
  slug
  title
  link
  authorId
  modified
  nodeType: __typename
  contentTypeName
  userCanEdit
  postCount
  author {
    node {
      ${GAuthor}
    }
  }
  collaborators {
    ${GUser}
  }
  pendingCollaborators {
    ${GUser}
  }
  images
  internalCollectionFields {
    staffOnly
    description
    private
    siteCollection
    requestToPromote
    promotionDenied
    requestedBy {
      ${GUser}
    }
    pendingInvites {
      email
    }
    recommendedPosts {
      ... on InternalDocument {
          databaseId
      }
      ... on Document {
          databaseId
      }
    }
  }
`;
