import { makeAutoObservable, toJS } from "mobx";
import { ApiClient } from "~libs/api";
import { ApiInputProps, parseFields, validateItems } from "~libs/utils";
import { TextValidator, EmailValidator } from "~libs/validators";

export class ContactFormStore {
  constructor() {
    makeAutoObservable(this);
  }

  client = new ApiClient(false);

  contactFormToEmail: string = "resourcecentre@savethechildren.org";

  reCaptchaValid: boolean = false;

  correctionModalOpen: boolean = false;

  formData: ApiInputProps[] = [
    {
      id: "toEmail",
      value: this.contactFormToEmail,
      param: "toEmail",
      label: "toEmail",
      type: "text"
    },
    { id: "fromUrl", value: "", param: "fromUrl", label: "fromUrl", type: "text" },
    {
      id: "name",
      value: "",
      error: "",
      type: "text",
      param: "name",
      label: "misc.yourName",
      placeholder: "",
      validator: {
        func: TextValidator,
        options: { errorName: "empty_field" }
      }
    },
    {
      id: "email",
      value: "",
      error: "",
      type: "email",
      param: "email",
      label: "misc.yourEmail",
      placeholder: "",
      validator: {
        func: EmailValidator,
        options: { errorName: "email" }
      }
    },
    {
      id: "textarea",
      value: "",
      error: "",
      type: "textarea",
      param: "textarea",
      label: "misc.message",
      placeholder: "",
      style: { height: "100%" },
      validator: {
        func: TextValidator,
        options: { errorName: "text" }
      }
    }
  ];

  get inputValid() {
    return this.formData.filter(data => ["name", "email", "textarea"].includes(data.id)).every(data => !data.error && data.value.length);
  }

  sendState: "idle" | "success" | "error" = "idle";

  toggleReCaptcha = () => {
    this.reCaptchaValid = !this.reCaptchaValid;
  };

  getParsedData = () => {
    const formData = new FormData();
    parseFields(toJS(this.formData), formData);

    return formData;
  };

  sendForm = async e => {
    if (!validateItems(this.formData, true)) {
      return;
    }

    const response = await this.client.sendContactForm(this.getParsedData());
    if (response.ok) {
      this.sendState = "success";
      setTimeout(() => {
        this.sendState = "idle";
        this.formData.forEach((data, index) => {
          this.formData[index].value = "";
        });
        this.reCaptchaValid = false;
      }, 5000);
    } else {
      console.error(response);
      this.sendState = "error";
      setTimeout(() => {
        this.sendState = "idle";
      }, 5000);
    }
  };

  toggleCorrectionModal = () => {
    this.correctionModalOpen = !this.correctionModalOpen;
  };
}
