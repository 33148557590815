import { makeAutoObservable, runInAction } from "mobx";
import { GraphqlClient } from "~libs/api";
import { ISingleDocumentAuthor } from "~models/Taxonomies";

interface singleAuthorCollection {
  letter: string;
  authors: ISingleDocumentAuthor[];
}

export interface IAuthorProfile {
  documentAuthor: ISingleDocumentAuthor;
}

interface IAuthorProfileMap {
  [key: string]: IAuthorProfile;
}

export class AuthorStore {
  constructor(private graphqlClient: GraphqlClient) {
    makeAutoObservable(this);
  }
  allAuthors: ISingleDocumentAuthor[] = [];

  firstNameAuthorsCollection: singleAuthorCollection[] = [];

  lastNameAuthorsCollection: singleAuthorCollection[] = [];

  collectionToRender: any = [];

  allAuthorsFirstNameLetters: string[] = [];

  allAuthorsLastNameLetters: string[] = [];

  filterLetters: string[] = [];

  sortResultsOptions: { key: string; value: string; selected: boolean }[] = [
    {
      key: "first_name",
      value: "First name",
      selected: true
    },
    {
      key: "last_name",
      value: "Last name",
      selected: false
    }
  ];

  get selectedSortingOption() {
    return this.sortResultsOptions.find(item => item.selected);
  }

  showPagination: boolean;

  currentPage: number = 1;

  totalNumberOfPages: number = 1;

  numberOfAuthorsPerPage: number = 30;

  currentNumberOfAuthorsShown: number = 1;

  totalNumberOfAuthors: number;

  searching: boolean = false;

  searchQuery: string = "";

  searchResults: any[] = [];

  authorProfiles: IAuthorProfileMap = {};

  requestStatus: "idle" | "pending" | "error" = "idle";

  initAuthorStore = (authors: ISingleDocumentAuthor[]) => {
    if (this.allAuthors.length === 0 && authors.length > 0) {
      this.allAuthors = authors;
      this.totalNumberOfAuthors = authors.length;
      this.splitAuthorsIntoCollections(authors);
      this.sortLastNameAuthorsCollection();
      this.collectAuthorLetters();
      this.setCollectionToRender();
    }
  };

  setCollectionToRender = () => {
    let tempCollectionToRender = [];

    if (!this.searching) {
      if (this.selectedSortingOption.key === "first_name") {
        tempCollectionToRender = this.firstNameAuthorsCollection;
      } else if (this.selectedSortingOption.key === "last_name") {
        tempCollectionToRender = this.lastNameAuthorsCollection;
      }
    } else {
      tempCollectionToRender = this.searchResults;
    }

    if (this.filterLetters.length > 0 && tempCollectionToRender.length > 0) {
      tempCollectionToRender = tempCollectionToRender.filter(collectionObject => this.filterLetters.includes(collectionObject.letter));
    }

    let authorsInCurrentCollection = this.getAuthorsFromCollection(tempCollectionToRender).length;

    if (authorsInCurrentCollection > this.numberOfAuthorsPerPage) {
      tempCollectionToRender = this.splitCollectionIntoPages(tempCollectionToRender);
      this.currentNumberOfAuthorsShown = this.getNumberOfAuthorsOnCollectionPage(tempCollectionToRender[this.currentPage - 1]);
    } else {
      this.showPagination = false;
      this.currentNumberOfAuthorsShown = authorsInCurrentCollection;
    }

    this.collectionToRender = tempCollectionToRender;
  };

  splitCollectionIntoPages = collection => {
    let collectionPages = [];
    let pageIndex = 0;
    if (collection && collection.length > 0) {
      collection.forEach(collectionObject => {
        if (collectionPages[pageIndex]) {
          let numberOfAuthorsOnCurrentPage = this.getNumberOfAuthorsOnCollectionPage(collectionPages[pageIndex]);

          if (numberOfAuthorsOnCurrentPage + collectionObject.authors.length <= this.numberOfAuthorsPerPage) {
            collectionPages[pageIndex].push(collectionObject);
          } else {
            collectionPages[pageIndex + 1] = [collectionObject];
            pageIndex += 1;
          }
        } else {
          collectionPages[pageIndex] = [collectionObject];
        }
      });
    }

    this.showPagination = true;
    this.totalNumberOfPages = collectionPages.length;
    return collectionPages;
  };

  getNumberOfAuthorsOnCollectionPage = collectionPage => {
    let numberOfAuthorsOnCollectionPage = 0;
    if (collectionPage) {
      collectionPage.forEach(collectionObject => {
        numberOfAuthorsOnCollectionPage += collectionObject.authors.length;
      });

      return numberOfAuthorsOnCollectionPage;
    }
  };

  collectAuthorLetters = () => {
    let authorsToTraverse;

    if (!this.searching) {
      switch (this.selectedSortingOption.key) {
        case "first_name":
          authorsToTraverse = this.getAuthorsFromCollection(this.firstNameAuthorsCollection);
          break;
        case "last_name":
          authorsToTraverse = this.getAuthorsFromCollection(this.lastNameAuthorsCollection);
          break;
      }
    } else {
      authorsToTraverse = this.getAuthorsFromCollection(this.searchResults);
    }

    authorsToTraverse.forEach((author: ISingleDocumentAuthor) => {
      let firstLetter;
      if (author.authorFields.firstName) {
        firstLetter = this.getFirstLetter(author.authorFields.firstName);
        if (!this.allAuthorsFirstNameLetters.includes(firstLetter)) this.allAuthorsFirstNameLetters.push(firstLetter);
      }

      if (author.authorFields.lastName) {
        firstLetter = this.getFirstLetter(author.authorFields.lastName);
        if (!this.allAuthorsLastNameLetters.includes(firstLetter)) this.allAuthorsLastNameLetters.push(firstLetter);
      }
    });
  };

  splitAuthorsIntoCollections = (authors: ISingleDocumentAuthor[]) => {
    authors.forEach(author => {
      let firstNameLetter = this.getFirstLetter(author.authorFields.firstName);
      const firstNameLetterIndex = this.firstNameAuthorsCollection.findIndex(collectionObject => collectionObject.letter === firstNameLetter);
      if (firstNameLetterIndex >= 0) {
        this.firstNameAuthorsCollection[firstNameLetterIndex].authors.push(author);
      } else {
        this.firstNameAuthorsCollection.push({
          letter: firstNameLetter,
          authors: [author]
        });
      }

      let lastNameLetter = this.getFirstLetter(author.authorFields.lastName);
      const lastNameLetterIndex = this.lastNameAuthorsCollection.findIndex(collectionObject => collectionObject.letter === lastNameLetter);
      if (lastNameLetterIndex >= 0) {
        this.lastNameAuthorsCollection[lastNameLetterIndex].authors.push(author);
      } else {
        this.lastNameAuthorsCollection.push({
          letter: lastNameLetter,
          authors: [author]
        });
      }
    });
  };

  sortLastNameAuthorsCollection = () => {
    if (this.lastNameAuthorsCollection) {
      this.lastNameAuthorsCollection = this.lastNameAuthorsCollection.sort(this.compareAuthorCollectionObjects);
      this.lastNameAuthorsCollection.forEach(letterCollection => {
        letterCollection.authors = letterCollection.authors.sort((first, second) => first.authorFields.lastName && first.authorFields.lastName.localeCompare(second.authorFields.lastName));
      });
    }
  };

  compareAuthorCollectionObjects = (firstObject, secondObject) => {
    var firstObjectLetter = firstObject.letter;
    var secondObjectLetter = secondObject.letter;
    return firstObjectLetter < secondObjectLetter ? -1 : firstObjectLetter > secondObjectLetter ? 1 : 0;
  };

  getFirstLetter = (string: string) => {
    return string?.charAt(0)?.toLowerCase();
  };

  getAuthorsFromCollection = (collection: singleAuthorCollection[]) => {
    let authors = [];

    collection &&
      collection.forEach(collection => {
        if (collection?.authors?.length > 0) authors = authors.concat(collection.authors);
      });

    return authors;
  };

  setSortOption = selectedOption => {
    const optionIndex = this.sortResultsOptions.findIndex(item => item.value === selectedOption.value);
    this.sortResultsOptions.forEach(item => (item.selected = false));
    this.sortResultsOptions[optionIndex].selected = true;
    this.searchAuthors();
    this.setCollectionToRender();
    this.resetFilterLetters();
  };

  toggleLetterInFilter = (letter: string) => {
    if (this.filterLetters.includes(letter)) {
      const index = this.filterLetters.indexOf(letter);
      this.filterLetters.splice(index, 1);
    } else {
      this.filterLetters.push(letter);
    }
    this.currentPage = 1;
    this.setCollectionToRender();
  };

  resetFilterLetters = () => {
    this.allAuthorsFirstNameLetters = [];
    this.allAuthorsLastNameLetters = [];
    this.collectAuthorLetters();
  };

  setPage = page => {
    this.currentPage = page;
    this.currentNumberOfAuthorsShown = this.getNumberOfAuthorsOnCollectionPage(this.collectionToRender[this.currentPage - 1]);
  };

  resetSearch = () => {
    this.searchQuery = "";
    this.searching = false;
    this.setCollectionToRender();
    this.resetFilterLetters();
  };

  setQuery = e => {
    const query = e.target.value.toLowerCase();
    if (query.length === 0) this.resetSearch();

    this.searchQuery = query;
    this.filterLetters = [];
    this.searchAuthors();
  };

  searchAuthors = () => {
    this.searching = true;
    const query = this.searchQuery;
    let results = [];

    let collectionToTraverse;

    switch (this.selectedSortingOption.key) {
      case "first_name":
        collectionToTraverse = this.firstNameAuthorsCollection;
        break;
      case "last_name":
        collectionToTraverse = this.lastNameAuthorsCollection;
        break;
    }

    collectionToTraverse.forEach(collection => {
      let innerResults = [];
      const { authors, letter } = collection;

      authors.forEach(author => {
        const { name } = author;
        let hasMatch = false;
        hasMatch = name.toLowerCase().includes(query);
        if (hasMatch && !innerResults.some(result => result.name === name)) {
          innerResults.push(author);
        }
      });

      if (innerResults.length > 0) {
        results.push({ letter: letter, authors: innerResults });
      }
    });
    this.searchResults = results;
    this.setCollectionToRender();
    this.resetFilterLetters();
  };

  fetchAuthorProfile = async (slug: string) => {
    this.requestStatus = "pending";
    const response = await this.graphqlClient.getAuthorProfile(slug);
    runInAction(async () => {
      if (response.ok) {
        const { data, errors } = await response.json();
        runInAction(() => {
          if (errors) {
            this.requestStatus = "error";
          } else {
            this.authorProfiles[slug] = data;
            this.requestStatus = "idle";
          }
        });
      } else {
        this.requestStatus = "error";
      }
    });
  };

  getAuthorProfile = async (slug: string, refresh: boolean) => {
    if (!this.authorProfiles[slug] || refresh) {
      await this.fetchAuthorProfile(slug);
    }
    return this.authorProfiles[slug];
  };
}
