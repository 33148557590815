import { ApiErrorResponse, ApiResponse, ApisauceInstance, create } from "apisauce";
import { IInternalCollection, ISingleWordpressInternalCollection } from "~models/InternalCollection";
import { ISubscriptionTaxonomyTypes, ISubscriptionBody } from "~models/Subscription";
import { ISingleDocumentTaxonomy } from "~models/Taxonomies";
import { IFieldSubset } from "~models/FormData";
import { cleanObject } from "~libs/utils";

const convertObjectToFormData = (data: any, form: FormData = null, namespace = ""): FormData => {
  const formData = form || new FormData();
  const formKey = namespace;

  if (data instanceof Date) {
    formData.append(formKey, data.toISOString());
  } else if (Array.isArray(data)) {
    data.forEach(item => {
      convertObjectToFormData(item, formData, `${formKey}[]`);
    });
  } else if (typeof data === "object" && !(data instanceof File)) {
    Object.keys(cleanObject(data)).forEach(key => {
      convertObjectToFormData(data[key], formData, formKey ? `${formKey}[${key}]` : `${key}`);
    });
  } else {
    formData.append(formKey, data);
  }

  return formData;
};
export class ApiClient {
  client: ApisauceInstance;

  constructor(authenticated: boolean) {
    this.client = create({
      baseURL: process.env.GATSBY_WP_API_URL,
      headers: {
        "Cache-Control": "no-cache",
        "Content-Type": "application/json"
      },
      timeout: 60000,
      withCredentials: authenticated
    });
  }

  /**
   * Normalizes the response that comes from wordpress.
   *
   * If a JWT error occurs, the status code will be 200 but the body contains a status code with a different status
   * This fixes so the response looks like a ApiResponse
   * @param response
   */
  normalizeWordpressResponse = <T extends unknown>(response: ApiResponse<T | IJWTError>) => {
    if (response && response.ok && response.data) {
      if (typeof response.data["statusCode"] !== "undefined") {
        response.data = response.data as IJWTError;
        return {
          ok: false,
          data: response.data.message,
          status: response.data.statusCode
        } as ApiErrorResponse<string>;
      }
    }

    return response as ApiResponse<T>;
  };

  redirect = (url: string) => {
    window.location.href = `${this.client.getBaseURL()}${url}`;
  };

  setToken = token => {
    if (token) {
      this.client.setHeader("Authorization", `Bearer ${token}`);
    } else {
      this.client.deleteHeader("Authorization");
    }
  };

  getTaxonomies = (types: ISubscriptionTaxonomyTypes[], lang: string) =>
    this.client.get<{ [key: string]: ISingleDocumentTaxonomy[] }>("/api/v1/taxonomies", {
      type: types.join(","),
      language: lang
    });

  getCollections = (lang: string) => this.client.get<{ collections: ISingleWordpressInternalCollection[] }>("/api/v1/collections", { lang: lang?.toUpperCase() });

  getCollectionDocuments = async (id: string, data: any) => this.normalizeWordpressResponse(await this.client.post<any>(`/api/v1/collections/${id}/documents`, data));

  getCollectionExcel = async (id: string) => this.normalizeWordpressResponse(await this.client.get<any>(`/api/v1/collections/${id}/excel`, {}, { responseType: "blob" }));

  getCollectionFiles = async (id: number) => this.normalizeWordpressResponse(await this.client.get<{ [key: string]: string[] }>(`/api/v1/collections/${id}/files`));

  getAuthorFiles = async (id: number) => this.normalizeWordpressResponse(await this.client.get<{ [key: string]: string[] }>(`/api/v1/authors/${id}/files`));

  getDocuments = async data => this.normalizeWordpressResponse(await this.client.post<any>("/api/v1/search_documents", data));

  exportExcel = async data =>
    this.normalizeWordpressResponse(
      await this.client.post<Blob>("/api/v1/export_excel", data, { responseType: "blob" })
    );

  exportAnalytics = async data =>
    this.normalizeWordpressResponse(
      await this.client.post<Blob>("/api/v1/export_analytics", data, { responseType: "blob" })
    );

  autocomplete = async data => this.normalizeWordpressResponse(await this.client.get<any>("/api/v1/autocomplete_documents" + data));

  createDocument = async data =>
    this.normalizeWordpressResponse(
      await this.client.post<any>("/api/v1/documents", convertObjectToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
    );

  createInternalDocument = async data =>
    this.normalizeWordpressResponse(
      await this.client.post<any>("/api/v1/internal_documents", convertObjectToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
    );

  createNewsletterSubscription = async (data: ISubscriptionBody) => {
    return this.normalizeWordpressResponse(await this.client.post<any>("/api/v1/subscriptions", data));
  };

  getNewsletterSubscriptions = async () => this.normalizeWordpressResponse(await this.client.get<ISubscriptionBody[]>("/api/v1/subscriptions"));

  destroyNewsletterSubscriptions = async id => this.normalizeWordpressResponse(await this.client.delete<any>(`/api/v1/subscriptions/${id}`));

  createInternalCollection = async (data: IInternalCollection) => await this.client.post<any>(`/api/v1/internal_collections`, data);

  updateInternalCollection = async (collectionId: number, data: { title: string; description: string; private: boolean }) => this.normalizeWordpressResponse(await this.client.put<any>(`/api/v1/internal_collections/${collectionId}`, data));

  deleteInternalCollection = async (collectionId: number) => this.normalizeWordpressResponse(await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}`));

  internalCollectionAuthor = async (collectionId: number, userId: number) => await this.client.put<any>(`/api/v1/internal_collections/${collectionId}/author/${userId}`);

  addPendingCollaborators = async (collectionId: number, userIds: number[]) => await this.client.put<any>(`/api/v1/internal_collections/${collectionId}/pending_collaborators`, { user_ids: userIds });

  removeCollectionCollaborator = async (collectionId: number, userId: number) => await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}/collaborators/${userId}`);

  removeCollectionPendingCollaborator = async (collectionId: number, userId: number) => await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}/pending_collaborators/${userId}`);

  addInternalCollectionPost = async (collectionId: number, postId: number) => await this.client.post<any>(`/api/v1/internal_collections/${collectionId}/posts/${postId}`);

  removeInternalCollectionPost = async (collectionId: number, postId: number) => await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}/posts/${postId}`);

  requestInternalCollectionPromotion = async (collectionId: number) => await this.client.put<any>(`/api/v1/internal_collections/${collectionId}/request_promotion`);

  revokeInternalCollectionPromotionRequest = async (collectionId: number) => await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}/revoke_promotion_request`);

  sendCollectionInvites = async (collectionId: number, data: { emails: string[] }) => this.normalizeWordpressResponse(await this.client.post<any>(`/api/v1/internal_collections/${collectionId}/send_invites`, data));

  removeCollectionInvite = async (collectionId: number, email: string) => await this.client.delete<any>(`/api/v1/internal_collections/${collectionId}/remove_invite/${email}`);

  sendContactForm = async data => {
    return this.client.post<Response>("/api/v1/send_contact_form", data);
  };

  updateUserPreferences = async data => {
    return this.client.post<Response>("/api/v1/update_user_preferences", data);
  };

  autocompleteUsers = async data => {
    return this.client.get<Response>("/api/v1/autocomplete_users?email=" + data);
  };

  getFields = async (subset: IFieldSubset) => await this.client.get<Response>(`/api/v1/fields/${subset}`);

  getIsEmailSCDomain = async (email: string) => await this.client.post<boolean>("/api/v1/sc_domain", JSON.stringify({ email: email }));

  submitAuthorClaim = async (authorId: number) => await this.client.post<boolean>(`/api/v1/claim_author/${authorId}`);

  updateAuthor = async (authorId: number, data: { role?: string; affiliation?: string; description?: string; profile_picture?: File | undefined }) =>
    this.normalizeWordpressResponse(
      await this.client.post<any>(`/api/v1/authors/${authorId}`, convertObjectToFormData(data), {
        headers: { "Content-Type": "multipart/form-data" }
      })
    );

  submitCorrection = async (data: { email?: string; description: string; issue: string; url: string }) => {
    return this.client.post<Response>("/api/v1/correction", data);
  };

  linkedInAuth = async (userId: number) => {
    return this.redirect(`/api/v1/authors/linkedin_auth?user_id=${userId}`);
  };

  getLinkedInData = async () => {
    return this.client.get<Response>("/api/v1/authors/linkedin_profile");
  };
}

interface IJWTError {
  success: boolean;
  statusCode: number;
  code: string;
  message: string;
}
