import { GDocumentTaxonomies } from "../graphqlQueryPartials";
import { GDocumentAuthor, GDocumentTaxonomiesListView } from "./taxonomies";

export const GDocumentModalView = `
  id
  databaseId
  title
  link
  nodeType: __typename
`;

export const GDocumentListView = `
  id
  databaseId
  title
  link
  slug
  nodeType: __typename
  documentThumbnail: featuredImage {
    image: node {
      mediaItemUrl
      altText
      title
    }
  }
  documentFields {
    abstract
    publicationYear
    documentFlares {
      peerReviewed
      recommended
    }
  }
  ${GDocumentTaxonomiesListView}
`;

export const GDocument = `
id
databaseId
title
slug
link
nodeType: __typename
contentTypeName
documentThumbnail: featuredImage {
  image: node {
    mediaItemUrl
    altText
    title
  }
}
documentFields {
  abstract
  publicationYear
  documentFlares {
    peerReviewed
    recommended
  }
  contributors {
    ... on DocumentAuthor {
      ${GDocumentAuthor}
    }
  }
  documents {
    type
    documentLink
    documentLinkTitle
    documentLinkType
    documentFile {
      id
      databaseId
      title
      caption
      altText
      description
      link
      slug
      uri
      mediaItemUrl
      mediaType
      mimeType
      fileSize
    }
    documentLanguage {
      name
      languageISOFields {
        iso
      }
    }
  }
}
${GDocumentTaxonomies}
`;
